import { template as template_94f48d1a2bea42a9ab78ad0000cd37f5 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_94f48d1a2bea42a9ab78ad0000cd37f5(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
