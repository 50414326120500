import { template as template_adbd529e1a774ef9a93a34603eb0c8a3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_adbd529e1a774ef9a93a34603eb0c8a3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
